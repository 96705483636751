<template>
  <div class="mepromise">
    <van-nav-bar
      title="练习助手"
      left-text=""
      left-arrow
      class="navbar"
      @click-left="goBack"
    />
    <div class="assistant">
      <div class="ctx">
        <div class="content">
          在练习时，你可能会有一些疑惑，我们整理了一些常见的问题，希望可以帮助你顺利地完成练习，获得更好地体验和收获~
        </div>
        <div class="ctx-title">
          <div class="title-ctx">每天练习要做些什么?</div>
          <div class="title-fk"></div>
        </div>
        <div class="content">
          <div>
            1.完成
            <span class="content-blue">【知识指南】</span>的学习，大约需要5分
          </div>
          <div>
            2.进入
            <span class="content-blue">【小练习】</span
            >，完成其中的思考或行为行动，大约需要5-10分钟;
          </div>
          <div>
            3.在
            <span class="content-blue">【情景训练场】</span
            >中完成一题测验并查看反馈，大约需要3分钟
          </div>
          <div>
            4.你可以在每天的任意时间(我们建议在晚上或者完成全部练习后)，在
            <span class="content-blue">【情绪日记】</span
            >中记录自己今天的情绪和感受;
          </div>
          <div>
            PS：你可以选择将【情绪日记】中的记录同步到【圈子】，和练习的伙伴们一起分享；你也可以勾选“仅自己可见”，珍藏属于自己的独特体验。
          </div>
        </div>

        <div class="ctx-title">
          <div class="title-ctx">【圈子】是什么地方？</div>
          <div class="title-fk"></div>
        </div>
        <div class="content">
          <div>
            在训练的首页（主页面）可以看到【圈子】的入口，这里是属于{{company}}小伙伴们的朋友圈。
            这里的ID，显示就是大家注册登录时输入的昵称和头像。
          </div>
          <div>
            你可以把情绪日记中记录的[天气]和[今日体验]同步在这里，和练习的小伙伴们分享，相互点赞、评论。
          </div>
          <div>
            PS：为了保证【圈子】互动空间的体验，大家在发言时要注意文明规范呀(⌒o⌒)
          </div>
        </div>

        <div class="ctx-title">
          <div class="title-ctx">【情绪胶囊】是什么？</div>
          <div class="title-fk"></div>
        </div>
        <div class="content">
          <div>
            在{{company}}首页（主页面）可以看到【情绪胶囊】的按钮，这是我们缓和情绪的“良方”。
          </div>
          <div>
            我们在其中准备了7个在情绪波动，或者状态不好时及时调整的方法，对应了不同的场景。
          </div>
          <div>
            如果在某个时刻你感觉到情绪或状态的困扰，可以选择一颗“胶囊”来帮助你。
          </div>
          <div>
            当然，你也可以随时打开胶囊的页面进行学习，更好地掌握调节情绪的有效方法，保持情绪的稳定。
          </div>
        </div>

        <div class="ctx-title">
          <div class="title-ctx">每个练习对应的“♡”是做什么的？</div>
          <div class="title-fk"></div>
        </div>
        <div class="content">
          <div>
            在【今日练习】中的每个练习后面，都有一个“♡”，这是一个代表“喜欢”的标记
          </div>
          <div>
            如果你在这个练习中体验很好，或许很喜欢这个练习，可以点击这个“♡”，给这个练习做一个标记。
          </div>
          <div>你可以在【练习档案】-【喜欢的练习】里找到你标记收藏过的练习</div>
        </div>

        <div class="ctx-title">
          <div class="title-ctx">练习列表中的练习是什么?</div>
          <div class="title-fk"></div>
        </div>
        <div class="content">
          练习列表中可以看到{{company}}7天的训练主题，你可以通过这里进入已经解锁和完成的练习，查看之前的练习记录和内容
        </div>
        <div class="content">
          也可以清晰地看到之前自己标记为“喜欢”的练习，再次查看练习内容
        </div>
        <div class="ctx-title">
          <div class="title-ctx">练习小助手</div>
          <div class="title-fk"></div>
        </div>
        <div class="content">
          如果你对练习仍有疑问，可以添加我们的【练习小助手】，小助手将尽力解答疑惑～
        </div>
        <div class="erweima">
          <img
            src="https://lesson.iapeap.com/images/EmotionalValue/erweima3.png"
            alt=""
          />
        </div>
        <div class="content-center">小助手将尽力解答疑惑~</div>
      </div>
    </div>
  </div>
</template>
<script>
import { memberLog } from "../../api/login";
export default {
  data() {
    return {
      starttime: "", //  进入页面时间
      endtime: "", //  退出页面时间
      time: "", //   页面累积时间
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
  mounted() {
    this.timer = setInterval(() => {
      this.time++;
      // console.log("页面累积浏览时间是：",this.time,"秒")
    }, 1000); // 每秒触发一次
    // ```````````获取当前的年月日时分秒，并以"2018-01-01 0:00"的格式显示
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // 补0操作，确保是两位数
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");

    this.starttime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    console.log("进入页面的系统时间是：", this.starttime);
  },
  beforeDestroy() {
    clearInterval(this.timer); // 清除定时器
    // ```````````获取当前的年月日时分秒，并以"2018-01-01 0:00"的格式显示
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // 补0操作，确保是两位数
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    this.endttime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    console.log("离开页面的系统时间是", this.endttime);

    //把参数传给后端接口，starttime、endttime、time
    let data = {
      startTime: this.starttime,
      endTime: this.endttime,
      userId: window.localStorage.getItem("userId"),
      pageName: "练习助手",
      timeStamp: this.time,
    };
    memberLog(data).then((res) => {});
  },
};
</script>

<style lang="less">
.navbar {
  background-color: #ededed;
}
/deep/ .van-nav-bar .van-icon {
  color: #464646;
}
.assistant {
  padding: 30px 20px;
  box-sizing: border-box;
  .ctx {
    background: #ffffff;
    box-shadow: 0px 2px 8px 0px rgba(185, 184, 184, 0.5);
    border-radius: 8px;
    padding: 20px;
    box-sizing: border-box;
    font-size: 13px;
    font-weight: 500;
    color: #000025;
    line-height: 18px;
    letter-spacing: 1px;
    .content {
      margin-bottom: 20px;
    }

    .ctx-title {
      .title-ctx {
        z-index: 999;
        position: relative;
        font-size: 18px;
        font-weight: 500;
        color: #000025;
        line-height: 25px;
      }
      .title-fk {
        width: 35px;
        height: 5px;
        background: #629ee9;
        position: relative;
        bottom: 8px;
        z-index: 998;
        transform: skew(-25deg);
      }
    }
    .erweima {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      img {
        width: 100px;
        height: 100px;
      }
    }
    .content-center {
      text-align: center;
    }
    .content-blue {
      color: #629ee9;
    }
  }
}
</style>
